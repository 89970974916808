.scrollTop {
    position: fixed; 
    /* width: 100%; */
    width:40px;
    right: 20px;
    bottom: 20px;
    align-items: center;
    float: right;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
  }
  
  .scrollTop:hover{
    opacity: 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }