.modal-div {
    font-family: sans-serif;
    text-align: center;
  }
  .modal {
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-container {
    position: absolute;
    width: 40%;
    background: #ffffff;
    left: 30%;
    top: 30%;
    padding: 20px;
  }
  @media screen and (max-width: 900px) {
    .modal-container {
      position: absolute;
      width: 50%;
      background: #ffffff;
      left: 25%;
      top: 30%;
      padding: 20px;
    }
  }
  @media screen and (max-width: 680px) {
    .modal-container {
      font-size: 12px;
      position: absolute;
      width: 60%;
      background: #ffffff;
      left: 20%;
      top: 30%;
      padding: 20px;
    }
    .contact-title{
      font-size: 20px;
    }
  }
  @media screen and (max-width: 520px) {
    .modal-container {
      position: absolute;
      width: 70%;
      background: #ffffff;
      left: 15%;
      top: 30%;
      padding: 20px;
    }
    .contact-title{
      font-size: 20px;
    }
  }
  @media screen and (max-width: 330px) {
    .modal-container {
      position: absolute;
      width: 80%;
      background: #ffffff;
      left: 10%;
      top: 20%;
      padding: 10px;
    }
    .contact-title{
      font-size: 18px;
    }
  }
  