.contain{
  width: 50px;
  height: 50px;
  /* border: 1px #aaa solid; */
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  border-radius: 50px;
  box-shadow: 0 5px 25px 0 rgba(0,0,0,.36);
  /* -webkit-transition: width .3s; */
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #fff;
  /* bring your own prefixes */
  /* transform: translate(-50%, -50%); */
}
.image{
  /* width: 40px;
  height: 40px; */
  font-size: 40px;
  color: #525254;
  /* background-image: url('../../images/icon/icons8-hand-with-pen-black.png'); */
}
.img-class{
  background-image: url('../../images/icon/icons8-hand-with-pen-black.png');
  width:100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;

}
.contain .caption{
  display: none;
}
/* .caption{
  opacity: 0;
  display: inline-block;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
} */


/* .contain:hover > caption{
  line-height: 20px; 
  display: inline;
  vertical-align: middle;
} */

/**********ON HOVER STYLE**********/
.contain:hover .caption{
  text-transform: uppercase;
  color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  opacity: 1;
  transition: all 2s;
  display: inline-block;
  margin-top: -35px;
  vertical-align: middle;
}
.contain:hover .image{
  color: #fff;
}
.contain:hover .img-class{
  background-image: url('../../images/icon/icons8-hand-with-pen-white.png');
  width:20%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;
  display: inline-block;

}
.contain:hover{
  width:200px;
  background-color: #0599e1;
}
